<template>
  <v-container fluid>
    <!-- start of editor -->
    <editor
      :seriesData="seriesArray"
      :branchesData="branchData"
      :defaultSeries="defaultSeries"
      :docCreate="docCreate"
      :docCopyTo="docCopyTo"
      :setup="setup"
      :tableRows="tableRows"
      :initial="dataItem"
      :tableData="tableData"
      :objectName="objectName"
      :activeObject="activeObject"
      :withPayment="withPayment"
      @data="save"
      @postData="postData"
    ></editor>
    <!-- end of editor -->

    <v-row dense>
      <v-col cols="9">
        <!-- <v-btn color="primary" class="right" @click="sendData">Save</v-btn> -->
      </v-col>
    </v-row>
    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/create-editor.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(
        {
          user: "user",
          getSavedCurrencies: "currency/currencies",
          getDocumentFormSetting: "documentFormSetting/getDocumentFormSettings",
          getDocumentModel: "getDocumentModel",

        }
    ),
    branchData() {
      return this.$store.state.branches;
    },
    loggedInUser() {
      return this.$store.state.user;
    },
  },
  data: () => ({
    dataItem: { parent: false, document_lines: [] },
    docStatus: true,
    docCreate: true,
    docCopyTo: true,
    tenantData: {},
    editMode: false,
    currencies: [],
    setup: {},
    hasAttachments:false,
    defaultSeries: {},
    seriesArray: [],
    branchesArray: [],
    saleDocs: [],
    objectName: null,
    record: {},
    tableData: [],
    tableRows: [],
    activeObject: null,
    baseObject: null,
    withPayment: null,
    options: {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  }),
  watch: {
    "$route.params.copyToDoc": {
      handler: "formSettings",
      immediate: true,
    },
    "$route.params.baseObject": {
      handler: "setBaseObject",
      immediate: true,
    },
    "$route.params.docId": {
      handler: "getDocs",
      immediate: true,
    },
  },
  methods: {

    setBaseObject(val) {
      this.baseObject = val;
    },

    save(data) {
      const self = this;
      data.ObjType = this.activeObject;

      const formData = new FormData();
      const files = data.oats || [];

      if (files.length > 0) {
        self.hasAttachments = true;
        files.forEach((file, f) => {
          const m_file = file.attachmedDetails;
          formData.append("files[" + f + "]", m_file);
        });
      }

      const url = "/documents";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            // const objectId = res.ResponseData.ObjType
            // const docEntry = res.ResponseData.id
            if (self.hasAttachments == true){
              self.$refs.snackbar.show("Uploading Documents", "green");
              formData.append("ObjType", res.ResponseData.newObjType);
              formData.append("DocEntry", res.ResponseData.id);
              this.uploadAttachments(formData,res.ResponseData);
            }
            // else {
            //   this.$store.commit("loader", false);
            //   //post to sap
            //   this.$store
            //       .dispatch("get", `/integrator/transactions/${objectId}?docEntry=${docEntry}`)
            //       .then((resp) => {
            //         self.postToSap(resp)
            //       })
            //       .catch((err) => {
            //         self.updateTransferredStatus(objectId,docEntry,"N");
            //         console.log(err);
            //       });
            // }
            this.$store.commit("loader", false);
            self.$router.push(`/sales/doc/${this.activeObject}`);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },

    postData(data) {
      const self = this;
      data.ObjType = this.activeObject;
      data.serviceCallId = this.$route.query.serviceCallId;
      // const formData = new FormData();
      // const files = data.oats || [];

      // if (files.length > 0) {
      //   self.hasAttachments = true;
      //   files.forEach((file, f) => {
      //     const m_file = file.attachmedDetails;
      //     formData.append("files[" + f + "]", m_file);
      //   });
      // }
      // if (self.hasAttachments == true){
      //   this.uploadAttachments(formData,res.ResponseData);
      // }else {
      self.postToSap(data)
      // }
    },

    uploadAttachments(formData,resData) {
      // const id = this.activeObject;
      // const docEntry = resData.id;
      // const objectId = resData.ObjType;
      console.log(resData)
      const url = "/upload_attachments";
      const self = this;
      this.$store
        .dispatch("post", { url, data: formData })
        .then((res) => {
          if (res.ResultCode == 1200) {
            // this.$store.commit("loader", false);
            self.$refs.snackbar.show(
                "Document Submitted successfully",
                "green"
            );
            self.hasAttachments = false;
            // this.$store
            //     .dispatch("get", `/integrator/transactions/${objectId}?docEntry=${docEntry}`)
            //     .then((resp) => {
            //       self.postToSap(resp);
            //     })
            //     .catch((err) => {
            //       // self.updateTransferredStatus(objectId,docEntry,"N");
            //       console.log(err);
            //     });
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },

    postToSap(data){
      const self = this;
      const ExternalErpUrl = self.loggedInUser.oadm.ExternalErpUrl+"/documents";
      axios
          .post(
              ExternalErpUrl,
              data,
              this.options
          )
          .then((resp) => {
            if (resp.data.ResultCode == 1200) {
              //handle attachments on update
              if (this.activeObject == 13 && resp.data.ResponseData.newObjType != 112) {
                axios
                    .post(
                        self.loggedInUser.oadm.TimsUrl,
                        resp.data.ResponseData,
                        this.options
                    )
                    .then((timsResp) => {
                      console.log(timsResp);
                    });

              }
            }
            this.$store.commit("loader", false);
            self.$router.push(`/sales/doc/${this.activeObject}?`);
          })
          .catch((err)=>{
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(err, "red");
          });
    },

    saveDraft(data) {
      const url = "/drafts";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/sales/ar-invoice");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },

    getCurrencies() {
      const self = this;
      if (self.getSavedCurrencies){
        self.currencies = self.getSavedCurrencies;
      }else{
        self.loading = true;
        this.$store
            .dispatch("get", `/currency`)
            .then((res) => {
              self.currencies = res;
              self.loading = false;
              self.$store.commit("currency/loadData",res)
            })
            .catch((err) => {
              this.$refs.snackbar.show(err, "red");
            });
      }
    },

    formSettings(objectId) {
      this.activeObject = objectId;
      const self = this;
      this.loading = true;
      if (self.getDocumentFormSetting[objectId]){
        this.$store.commit("loader", false);
        const documentFormSetting = self.getDocumentFormSetting[objectId];
        self.setup = documentFormSetting;
        self.tableRows = documentFormSetting.tabs[0].tableRows;
        self.seriesArray = documentFormSetting.Series;
        self.defaultSeries = documentFormSetting.DfltSeries;
        self.usersArray = documentFormSetting.users;
        self.branchesArray = documentFormSetting.branches;
        self.BPLId = documentFormSetting.BPLId;
        self.loading = false;
      }
      else{
        this.$store
            .dispatch("get", `/form_settings/${objectId}`)
            .then((res) => {
              var newDocumentFormSetting = { ...this.getDocumentFormSetting };
              newDocumentFormSetting[objectId] = res;
              this.$store.commit("documentFormSetting/loadData", newDocumentFormSetting);
              self.setup = res;
              self.tableRows = res.tabs[0].tableRows;
              self.seriesArray = res.Series;
              self.defaultSeries = res.DfltSeries;
              self.usersArray = res.users;
              self.branchesArray = res.branches;
              self.BPLId = res.BPLId;
              self.loading = false;
            })
            .catch((err) => {
              this.$store.commit("loader", false);
              console.log(err, "error");
            });
      }

      // set object Name
      if(self.getDocumentModel[objectId]){
        self.objectName = self.getDocumentModel[objectId].DocumentName;
      }
      else {
        this.$store
            .dispatch("get", `/doc_model/${objectId}`)
            .then((res) => {
              var newDocumentModel = { ...this.getDocumentModel };
              newDocumentModel[objectId] = res;
              this.$store.commit("setDocumentModel", newDocumentModel);
              self.objectName = res.DocumentName;
            })
            .catch((err) => {
              this.$refs.snackbar.show(err, "red");
            });
      }
      // end

      if (this.activeObject !== 13) {
        this.withPayment = "N";
      }
      if (this.activeObject == 13) {
        this.withPayment = "Y";
      }
    },

    getDocs(id) {
      var isDoc = this.$route.query.isDoc;
      if (this.baseObject !== null) {
        this.$store.commit("loader", true);
        const objectId = this.baseObject;
        if (id) {
          const self = this;
          this.$store
            .dispatch("get", `/documents/${objectId}/${id}?isDoc=${isDoc}&copyTo=1`)
            .then((res) => {
              let document_lines = res.document_lines;
              document_lines.forEach((doc) => {
                doc.BaseType = objectId;
                doc.BaseEntry = id;
                doc.BaseLine = doc.LineNum;
              });
              self.tableData = document_lines;
              self.dataItem = res;
              self.dataItem.BaseType = objectId;
              self.dataItem.BaseEntry = id;
              self.dataItem.DocNum = null;
              this.$store.commit("loader", false);
            })
            .catch((err) => {
              this.$store.commit("loader", false);
              this.$refs.snackbar.show(err, "red");
            });
        }
      }
    },

  },
  created() {
    this.getCurrencies();
  },
};
</script>